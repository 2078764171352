export const useMenuHQ = () => {
  const menu = [
    { title: 'Dashboard', to: '/hq/dashboard' },
    { title: 'Finance', to: '/hq/finance/main' },
    { title: 'General Manager', to: '/hq/management/list' },
    { title: 'IT', to: '/hq/user_request/list' },
    { title: 'Marketing', to: '/hq/marketing/contract/list' },
    { title: 'Purchase Request', to: '/hq/purchaserequest' },
    { title: 'Purchaser', to: '/hq/purchaser/list' },
    { title: 'Trader', to: '/hq/trader/contract/list' },
    { title: 'Store', to: '/hq/store/list' },
    { title: 'Product Request', to: '/hq/product_request/list' }
  ]
  return menu
}
